import React from 'react'
import styled from "styled-components";

const IconLinkStyles = styled.div`
  height: 80px;
  border-radius: 8px;
  color: #ffffff;
  border: 1px solid #fff;

  a {
    display: block;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    position: relative;
    background: #000;
    border-radius: 8px;
    &:hover {
      background: #adadad;
    }

    img {
      max-height: 100%;
      margin-left: 20px;
    }

    span {
      color: #fff;
      font-size: 24px;
      position: absolute;
      right: 30%;
      top: 27px;
    }
  }

`

export const IconLink = ({icon, path}) => {
    return(
        <IconLinkStyles>
           <a href={path} target="_blank">
               <img src={`./img/${icon}.png`} alt={icon} />
               <span>{icon.charAt(0).toUpperCase() + icon.slice(1)} {icon === 'apple' && 'Music'}</span>
           </a>
        </IconLinkStyles>
    )
}