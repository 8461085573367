import React, { useState } from 'react'
import styled from 'styled-components'

const ContactStyle = styled.div`
  padding: 10px;
  width: 100%;
  
  form {
    width: 100%;
    padding: 25px;
    background: #848585;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    @media(max-width: 900px) {
      top: 70px;
    }
    input {
      height: 25px;
      border-radius: 6px;
      margin-bottom: 20px;
    }

    textarea {
      height: 250px;
      max-width: 450px;
      width: 100%;
      border-radius: 6px;
      margin-bottom: 20px;
    }

    button {
      width: 200px;
      height: 50px;
      border: none;
      border-radius: 6px;
      background: #5b84a1;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.5) 3px 3px;
    }
  }
`

export const Contact = () => {
    const [sent, setSent] = useState(false)
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmitForm = event => {
        event.preventDefault();
        setLoading(true)
        fetch('https://69nura0jf0.execute-api.eu-west-2.amazonaws.com/production/SendEmail',
            {
                method: 'POST',
                headers: {
                    'x-api-key': 'fimC3Ezggb2vY0Ju0yPRz6AFuYJUmilz6EJCIoV7',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, subject, message,})

            }).then((response) => {
            if (response.ok) {
                setSent(true)
                setLoading(false)
            } else {
                return response.json().then((json) => console.log(json))
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <ContactStyle>
            <form onSubmit={handleSubmitForm}>
                {
                    !sent ?
                        <>
                            <h2 className="title">Send me a message!</h2>
                            <div className="form-item">
                                <label>Your email:</label><br/>
                                <input type="email" name="email" value={email} onChange={event => setEmail(event.target.value)} disabled={loading} />
                            </div>
                            <div className="form-item">
                            <label>Subject:</label><br/>
                            <input type="text" name="subject" value={subject} onChange={event => setSubject(event.target.value)} disabled={loading} />
                            </div>
                            <div className="form-item">
                            <label>Message:</label><br/>
                            <textarea name="message" value={message} onChange={event => setMessage(event.target.value)} disabled={loading} /><br/>
                            </div>
                            <button type="submit" disabled={loading}>Submit</button>
                        </>
                    :
                        <div className="success">Message sent successfully!</div>
                }

            </form>
        </ContactStyle>
    )

}