import styled from 'styled-components'
import { IconLink } from './IconLink'

const LatestReleaseStyle = styled.div`
  .release-title {
    width: 100%;
    font-size: 24px;
    font-family: "Franklin Gothic Demi", sans-serif;
    font-weight: bold;
    color: #fff;
  }
  img {
    max-width: 434px;
    margin-bottom: 10px;
  }
  .button-link {
    margin-top: 25px;
  }
`

export const Album = ({title, coverPath = null, alt, iframme, youTube = null, amazon = null, pandora = null, itunes = null, apple = null}) => {

    return (
        <LatestReleaseStyle>
            <div className="release-title">{title}</div>
            {
                coverPath &&
                    <div>
                        <img src={coverPath} alt={alt} />
                    </div>

            }
            {iframme}
            {
                youTube &&
                    <div className="button-link">
                        <IconLink icon="YouTube" path={youTube} />
                    </div>

            }

            {
                amazon &&
                <div className="button-link">
                    <IconLink icon="amazon" path={amazon} />
                </div>

            }
            {
                pandora &&
                <div className="button-link">
                    <IconLink icon="pandora" path={pandora} />
                </div>

            }
            {
                itunes &&
                <div className="button-link">
                    <IconLink icon="itunes" path={itunes} />
                </div>

            }
            {
                apple &&
                <div className="button-link">
                    <IconLink icon="apple" path={apple} />
                </div>

            }
        </LatestReleaseStyle>
    )
}