import React from 'react'
import { Album } from '../components/Album'
import styled from 'styled-components'

const EclecticStyle = styled.div`
  margin-top: 80px;
`

export const Eclectic = () => {

    return (
        <EclecticStyle>
            <Album title="Eclectic & Foregone" coverPath="./img/Cover.jpg" alt="Eclectic & Foregone" iframme={
                <iframe src="https://open.spotify.com/embed/album/3f2K8S10zwvjDcrLNNGLys" width="434px" height="80"
                        frameBorder="0" allowTransparency="true" allow="encrypted-media" />}
            youTube="https://youtube.com/playlist?list=OLAK5uy_kh_bVfPCtYwoYT_54roSOqSnYk1Pzr7rM"
                   amazon="https://www.amazon.com/Eclectic-Foregone-Raz-Koller/dp/B086698HM3"
                   pandora="https://www.pandora.com/artist/raz-koller/eclectic-and-foregone/ALx5w92hv7ZPJj6"
                   itunes="https://music.apple.com/gb/album/eclectic-and-foregone/1503723442"
                   apple="https://music.apple.com/us/album/eclectic-and-foregone/1503723442"
            />
        </EclecticStyle>
    )
}