import React from 'react'
import {Helmet} from "react-helmet";
export const Home = () => {

    return (
        <>
            <Helmet>
                <title>Raz Koller - Homepage</title>
                <meta name="description" content="Not yet... But coming soon" />
            </Helmet>
            <div style={{background: 'black', color: 'white', width: 300, height: 200, fontSize: 40, position:'relative', margin:'auto', textAlign:'center', top:'5%' }}>Please Hold.</div>
        </>


    )
}