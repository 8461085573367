import {useState} from "react";
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const SideMenuStyle = styled.header`
  height: 100%;
  font-family: "Franklin Gothic Demi", sans-serif;
  position: relative;
  .desktop {
    max-width: 700px;
    padding: 50px;
    min-width: 250px;
    @media(max-width: 900px) {
      display: none;
    }
  }
  
  .mobile {
    display: none;
    min-width:20px;
    @media(max-width: 900px) {
      display: block;
    }
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    .hamburger {
      cursor: pointer;
      position: absolute;
      top: 20px;
      left: 20px;
      div {
        width: 35px;
        height: 5px;
        margin: 6px 0;
        background: #fff;
        color: #fff;
      }
    }
    .menu {
      background: #080707;
      border-radius: 8px;
      padding: 20px;
      position: absolute;
      left: -300px;
      top: 90px;
      min-width: 250px;
      transition: left 0.3s linear;
      &.show {
        left: 0;
      }
    }
  }
  
  a, a:visited {
    color: #fff;
  }
  h2 {
    font-size: 38px;
  }
  ul {
    list-style-type: none;
    padding: 0; 
    margin: 0;
    font-family: "Franklin Gothic Demi", sans-serif;
    font-weight: bold;
    color: #fff;

    li {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 20px;
      ul {
        margin: 15px 0 15px 0;
        li {
          padding-left: 15px;
          margin-bottom: 10px;
          font-size: 18px;
          text-transform: none;
        }
      }
    }
  }
`

export const SideMenu = () => {
    const [showMenu, setShowMenu] = useState(false)

    return(
        <SideMenuStyle>
            <div className="desktop">
                <h2><Link to="/">Raz Koller</Link></h2>
                    <ul>
                        <li>Music
                            <ul>
                                <li><Link to="/">Damage! (2022) </Link></li>
                                <li><a href="https://distrokid.com/hyperfollow/razkoller/manner-morose" target="_blank">Manner Morose (2021)</a></li>
                                <li><Link to="/eclectic">Eclectic & Forgone (2020)</Link></li>
                            </ul>
                        </li>
                        <li>Videos
                            <ul>
                                <li><Link to="/animation">Animation</Link></li>
                                <li><Link to="/philosophy">Philosophy</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/about">About me</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
            </div>
            <div className="mobile">
                <div className="hamburger" onClick={() => setShowMenu(!showMenu)}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={`menu ${showMenu ? 'show' : ''}`}>
                    <h2><Link onClick={() => setShowMenu(false)} to="/">Raz Koller</Link></h2>
                    <ul>
                        <li>Music
                            <ul>
                                <li><Link onClick={() => setShowMenu(false)} to="/">Damage! (2022) </Link></li>
                                <li><a href="https://distrokid.com/hyperfollow/razkoller/manner-morose" target="_blank">Manner Morose (2021)</a></li>
                                <li><Link onClick={() => setShowMenu(false)}  to="/eclectic">Eclectic & Forgone (2020)</Link></li>
                            </ul>
                        </li>
                        <li>Videos
                            <ul>
                                <li><Link onClick={() => setShowMenu(false)}  to="/animation">Animation</Link></li>
                                <li><Link onClick={() => setShowMenu(false)}  to="/philosophy">Philosophy</Link></li>
                            </ul>
                        </li>
                        <li><Link onClick={() => setShowMenu(false)}  to="/about">About me</Link></li>
                        <li><Link onClick={() => setShowMenu(false)}  to="/contact">Contact</Link></li>
                    </ul>
                </div>

            </div>
        </SideMenuStyle>
    )
}