import React from 'react'
import styled from 'styled-components'

const PhilosophyStyle = styled.div`
  color: #fff;
  padding-top: 100px;
  max-width: 100%;
  
`

export const Philosophy = () => {

    return (
        <PhilosophyStyle>
            <h2>Philosophy</h2>
            <div className="videos">
                <div className="video-item">
                    <h3>You're Not Rare, You're Random</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/KrlyAXnxstY"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>You Love Bad Things</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/_OXuVHmDUBU"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>You Don't Have Free Will - You Have Choice</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/j6rGJHknnWM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
            </div>
        </PhilosophyStyle>
    )
}