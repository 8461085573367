import React from 'react'
import styled from 'styled-components'

const VideosStyle = styled.div`
  color: #fff;
  padding-top: 100px;
  max-width: 100%;
  
`

export const Videos = () => {

    return (
        <VideosStyle>
            <h2>Animation</h2>
            <div className="videos">
                <div className="video-item">
                    <h3>Broken Hills #1 - Gavin Doesn't Want Kids</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6JXCZffkYvk"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>Broken Hills #1.5 - The Way We Were</h3>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/rySkrFHps3A"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>Broken Hills #2 - Gavin and the Flat Person</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DwNYmoZh-e0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>Broken Hills #2.5 - The Man on the Hill</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/EdeIjEVRRsI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>Broken Hills #3 - Gavin Doesn't Want to Go</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/IKRPY8Rvtm0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
                <div className="video-item">
                    <h3>Broken Hills #3.5 - Gavin and the Painter</h3>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6EQRbZ_HLrM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </div>
            </div>
        </VideosStyle>
    )
}