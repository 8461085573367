import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SideMenu } from "./components/SideMenu";
import { Home } from "./pages/Home";
import { Eclectic } from "./pages/Eclectic";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Videos } from "./pages/Videos";
import { Philosophy } from "./pages/Philosophy";
import React from "react";
import {Helmet} from "react-helmet";

function App() {
  return (
      <Router>
          <Helmet>
              <title>Raz Koller's Official Website</title>
              <meta name="description" content="The official web site of musician and artists Raz Koller" />
          </Helmet>
          <div className="site-wrapper">
            <Switch>
                <Route path={["/", "/home"]}>
                <Home />
                </Route>
            </Switch>
          </div>
      </Router>
  );
}

export default App;
