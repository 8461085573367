import React from 'react'
import styled from 'styled-components'
import {Social} from "../components/Social";

const AboutStyle = styled.div`
  color: #fff;
  padding-top: 100px;
  max-width: 100%;
  
`

export const About = () => {

    return (
        <AboutStyle>
            <div className="home-division">
                <div className="portrait">
                    <img src="./img/DSCF4605 - even smaller.jpg" alt="Raz Koller" />

                </div>
                <div className="greeting">
                    Welcome! <br />
                    I'm Raz. I'm an indie musician, an artist and writer. <br />
                    You can find here links to my albums, videos and other materials (coming soon). <br />
                    If you like what I'm doing the best way to know what I'm doing is through my social media (linked below), especially my YouTube channel.  <br/>
                    Oh, and I also update this space from time to time too... <br/>
                    <br/>
                    I hope you enjoy!<br />
                    <br />
                    Raz.
                    <br/>
                    <Social />
                    <hr />
                </div>
            </div>

        </AboutStyle>
    )
}