import styled from "styled-components";

const SocialStyle = styled.div`
  height: 40px;
  margin-top: 20px;
  a {
    margin-right: 20px;
  }
  img {
    max-height: 100%;
  }
`

export const Social = () => {
    return (
        <SocialStyle>
            <a href="https://www.youtube.com/channel/UCh9yE-LvoB1yzQVtaApNDWQ" target="_blank"><img src="./img/social-youtube.png" alt="youtube" /></a>
            <a href="https://twitter.com/RazKoller" target="_blank"><img src="./img/social-twitter.png" alt="twitter" /></a>
            <a href="https://www.instagram.com/raz.koller" target="_blank"><img src="./img/social-insta.png" alt="instagram" /></a>
        </SocialStyle>
    )
}